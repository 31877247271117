import { useState } from '#app';

export const homeSectionsId = {
  about: 'about',
  how: 'how-it-works',
  faq: 'faq',
  contact: 'contact',
} as const;

export function useHomeSections() {
  return useState<{ activeId: string }>('homeSections', () => ({
    activeId: homeSectionsId.about,
  }));
}
