<template>
  <div class="wrapper relative flex justify-between rfs:gap-x-92">
    <nav
      class="sticky hidden min-w-194 max-w-246 flex-auto self-start rfs:top-0 rfs:pt-216 md:block"
    >
      <ul class="flex flex-col">
        <li v-for="navItem in navItems" :key="navItem.id">
          <NuxtLink
            class="relative flex h-40 items-center justify-end border-r-1 text-12 uppercase rfs:pr-30"
            :class="[
              navItem.id === homeSections.activeId
                ? 'font-extrabold text-white rfs:text-28'
                : 'text-primary',
            ]"
            :href="navItem.href"
            @click="() => (homeSections.activeId = navItem.id)"
          >
            <span
              :class="[
                'pointer-events-none absolute top-1/2 -translate-y-1/2 rounded-full ',
                navItem.id === homeSections.activeId
                  ? '-right-11 size-21 border-8 border-primary bg-white shadow-[0_0_0_1px_white]'
                  : '-right-2 size-3 bg-primary',
              ]"
            ></span>
            {{ navItem.label }}
          </NuxtLink>
        </li>
      </ul>
    </nav>
    <div>
      <HomeContentSection :id="homeSectionsId.about" title="About" text>
        <AboutContent />
      </HomeContentSection>
      <HomeContentSection :id="homeSectionsId.how" title="How" text>
        <HowContent />
      </HomeContentSection>
      <HomeContentSection :id="homeSectionsId.faq" title="FAQ">
        <FaqContent />
      </HomeContentSection>
      <HomeContentSection :id="homeSectionsId.contact" title="Contact">
        Contact
      </HomeContentSection>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AboutContent from '~/components/Sections/Home/AboutContent.vue';
import FaqContent from '~/components/Sections/Home/FaqContent.vue';
import HomeContentSection from '~/components/Sections/Home/HomeContentSection.vue';
import HowContent from '~/components/Sections/Home/HowContent.vue';
import {
  homeSectionsId,
  useHomeSections,
} from '~/components/Sections/Home/useHomeSections';

const homeSections = useHomeSections();

const navItems = [
  {
    id: 'home',
    label: 'Home',
    href: '/',
  },
  {
    id: homeSectionsId.about,
    label: 'About',
    href: '/#about',
  },
  {
    id: homeSectionsId.how,
    label: 'How It Works',
    href: '/#how-it-works',
  },
  {
    id: homeSectionsId.faq,
    label: 'FAQ',
    href: '/#faq',
  },
  {
    id: homeSectionsId.contact,
    label: 'Contact Us',
    href: '/#contact',
  },
];
</script>
