<template>
  <NuxtLayout fixed-header guest>
    <main class="overflow-hidden">
      <IntroSection />
      <HomeSections />
    </main>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import HomeSections from '~/components/Sections/Home/HomeSections.vue';
import IntroSection from '~/components/Sections/IntroSection.vue';

useSeoMeta({
  title: 'GioPulse',
});
</script>
